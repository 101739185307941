<template>
  <div class="radio-button__wrapper">
    <input
      class="radio-button__input"
      type="radio"
      :name="radioGroup.name"
      :value="radioGroup.value"
      :checked="radioGroup.value === selected"
      :id="radioGroup.value"
      @change="handle($event)">
    <label class="radio-button__label" :for="radioGroup.value">
      <img v-if="radioGroup.img" class="radio-button__img" :src="radioGroup.img" alt=""/>
      <span class="radio-button__title">{{ label || radioGroup.value }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioButton',
  props: ['radioGroup', 'selected', 'label'],
  methods: {
    handle(evt) {
      this.$emit('input', { newValue: evt.target.value })
    }
  }
}
</script>

<style scoped lang="sass">
.radio-button
  &__label
    display: flex
    align-items: center
    height: 100%
    padding: rem(15)
    margin: 0 0 rem(10)
    border: 1px solid transparent
    border-radius: $block-radius
    background: $bg-grey
    &::before, &::after
      content: ''
      display: block
      position: absolute
      border-radius: 50%
    &::before
      width: rem(24)
      height: rem(24)
      top: rem(10)
      right: rem(10)
      background: #F3F3F3
      border: 1px solid $border-color
    &::after
      width: rem(12)
      height: rem(12)
      top: rem(17)
      right: rem(17)
  &__wrapper
    position: relative
  &__img
    display: flex
    flex-shrink: 0
    width: rem(66)
    height: rem(66)
    margin: 0 rem(20) 0 0
    border-radius: $radius
    background: $bg-grey
    overflow: hidden
  &__title
    @extend %18
    font-weight: 400
    text-align: left
.radio-button__input:checked + .radio-button__label
  background: $white
  border-color: $border-color
  &::before
    background: $white
    border-color: $main-color
  &::after
    background: $main-color
</style>
