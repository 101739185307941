<script>

import RadioButton from '@/components/dump/RadioButton.vue'
import { CalcTypes } from '@/utils/types'

export default {
  name: 'AlgorithmSwitcher',
  components: { RadioButton },
  props: ['selectedAlgorithm'],
  computed: {
    buttons() {
      return [
        {
          label: this.$t('message.algorithms.pro'),
          value: CalcTypes.pro,
          name: 'algorithm'
        }, {
          label: this.$t('message.algorithms.lite'),
          value: CalcTypes.lite,
          name: 'algorithm'
        }
      ]
    }
  },
  methods: {
    setAlgorithm(evt) {
      this.$emit('setAlgorithm', evt.newValue)
    }
  }
}
</script>

<template>
<article class="algorithm-switcher">
    <RadioButton
      v-for="button of buttons"
      :key="`button__${button.value}`"
      :radioGroup="button"
      :selected="selectedAlgorithm"
      :label="button.label"
      @input="setAlgorithm($event)"
    />
</article>
</template>

<style lang="sass">
.algorithm-switcher
  display: flex
  grid-gap: rem(20)
  margin-top: rem(30)
  +media((justify-content: (0: space-between, 580: flex-start)))
  .radio-button
    &__wrapper
      +media((min-width: (0: 47%, 580: auto)))
    &__label
      justify-content: center
      padding: rem(16) rem(30)
      +media((padding: (0: rem(16), 580: rem(16) rem(30))))
      cursor: pointer
      @extend %dark
      &::before, &::after
        content: none!important
    &__title
      @extend %14
  .radio-button__input:checked + .radio-button__label
    @extend %light
</style>
